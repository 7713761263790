// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")
require("packs/flickity.pkgd.min")
require("packs/flashes")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'bootstrap'
import './gem.js.erb'
import "youtube"

import Cookies from 'js.cookie';
window.Cookies = Cookies;

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
})

$(function() {
  
  // search panel toggle.
  $("body").click(function() {
    $(".search_panel").removeClass("show");
  });

  $(".search_panel .submit_search, .search_panel i").click(function(event){
    event.stopPropagation();
    $(".search_panel form").submit();
  });

  $(".search_panel").click(function(event){
    event.stopPropagation();
    return false;
  });
  
  $("a.search_button").click(function(event){
    event.stopPropagation();
    $(".search_panel").toggleClass("show");
    return false;
  });

  $("a.edit_comment").click(function(e){
      $(".edit_comment_form").hide();
      $(".forum_comment").show();
      var id = $(this).data("comment-id");
      $("#forum_comment_"+id).hide();
      $("#comment_form_"+id).show();
      return false;
  });

  $(".menu-icon").click(function(e){
    $(".menu_container").show();
    window.scrollTo(0,0);
    return false;
  });
 
  $(".close-button").click(function(e){
    $(".menu_container").hide();
    return false;
  });

  $(".menu_container a").click(function(e){
    $(".menu_container").hide();
  });

  $(".chapter_header").click(function(){
    $(".chapter_wrapper").toggleClass("show");
  });
 

  function is_touch_device() {
    // Checks for existence in all browsers and IE 10/11 & Surface
    return 'ontouchstart' in window || navigator.maxTouchPoints;
  };

  if(!is_touch_device()) {
      $('#categorySelect').flickity({
        // options
        cellAlign: 'left',
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true
      });
      $('#categorySelect').addClass("overflow");
  } else {
    $('#categorySelect').removeClass("overflow");
  };

  $('#categorySelect a').click(function(e){
    $('#categorySelect a').removeClass('active');
    $(this).addClass('active');
  });

  $('a.report_comment').click(function() { 
    $(this).text('Reported'); 
    $(this).fadeOut(1200); 
    $(this).closest('div.menu').fadeOut(1200); 
  });
});
